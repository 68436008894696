const unsupportedBrowserUrl = '/unsupported-browser';

const browserTests = [
  "flexbox",
  "promises",
  "classlist",
  "dataset",
  "srcset",
  "svg",
  "inlinesvg",
  "svgasimg",
  "csspositionsticky",
  "fetch",
  "containerqueries"
];

let failedTests = [];

Modernizr.addTest('containerqueries', function() {
  return ("container" in document.documentElement.style);
});

browserTests.forEach(function(browserTest) {
  if (!Modernizr[browserTest]) {
    failedTests.push(browserTest);
  }
});

if (failedTests.length != 0 && window.location.href.indexOf(unsupportedBrowserUrl) == -1) {
  window.location = unsupportedBrowserUrl + '?failed=' + failedTests.join();
}
